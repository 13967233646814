<template>
<Reg-layouts>
   <div class="main__content">
        <div :class="{ 'popup-visible' : this.user.register_error }">
            <div class="popup-wrap">
                <div class="popup successfully-popup" :class="{ 'active' : this.user.register_error}">
                    <form action="#" class="popup__form">
                        <div class="popup__header">
                            <div class="popup__title">
                                <span class="title-text"></span>
                            </div>
                            <div class="popup-remove" @click="popupRemove">
                                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                        fill="#333333"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                        fill="#333333"/>
                                </svg>
                            </div>
                        </div><!-- //popup__header -->
                        <div class="popup__content">
                            <div class="popup__section">
                                <div v-if="this.user.register_error.email" class="wysiwyg">
                                    <p  v-for="(item, index) in this.user.register_error.email" :key="index">
                                        {{ item }}
                                    </p>
                                </div>
                                <div v-if="this.user.register_error.company" class="wysiwyg">
                                    <p  v-for="(item, index) in this.user.register_error.company._schema" :key="index">
                                        {{ item }}
                                    </p>
                                </div>
                                <div v-if="this.user.reg_ind_error" class="wysiwyg">
                                    <p>
                                        {{ this.user.reg_ind_error }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="popup__footer">
                            <div class="btn-wrap btn-wrap_secondary content-right single-btn-right">
                                <div class="form-field"><div class="form-field__content"><button @click.prevent="popupRemove" class="btn btn_primary">Ok</button></div></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>



        <form class="main__form" autocomplete="off" v-on:submit.prevent="signup">
            <div class="section section_mobile-secondary">
                <div class="section__header">
                    <div class="section__wysiwyg wysiwyg">
                        <h1>Create An Account {{this.user.register_role.charAt(0).toUpperCase() + this.user.register_role.slice(1)}} </h1>
                    </div>
                </div>
                <div class="section__content">

                    <div class="account">
                    <div class="account__steps steps">
                        <div class="steps__item step">
                            <div class="step__header">
                                <div class="step__number">
                                    <span class="number-text">1</span>
                                </div>
                                <div class="step__title">
                                    <span class="title-text">Information</span>
                                </div>
                            </div>
                            <div class="step__content">
                                <div class="row">
                                    <div class="col col_6 col_mob-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">First Name *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input v-model="firstname" class="form-field__input" type="text"
                                                        placeholder="Please enter first name"
                                                        :required="true">
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>

                                    <div class="col col_6 col_mob-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Last Name *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input v-model="lastname" class="form-field__input" type="text"
                                                        placeholder="Please enter last name"
                                                        :required="true">
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col col_6 col_mob-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label v-if="this.user.register_role !== 'entrepreneur'" class="form-field__title">Company Name</label>
                                                <label v-else class="form-field__title">Name of Start up</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input
                                                v-model="companyname"  class="form-field__input"
                                                :disabled="user.invitation.type == 'company'"
                                                v-on:input="companynameHandler"
                                                type="text">
                                            </div>
                                        </div><!-- //form-field -->

                                        <div class="companyname-popup" :class="{ 'active' : this.company.regCompanyPopup }">
                                            <p>This Startup name is taken. Do you want to join the StartUP team?</p>
                                            <div class="btn-group btn-group_secondary content-left">
                                                <a href="#" class="btn btn_primary">
                                                    <span @click="joinCompany(true)" class="btn__text">Yes</span>
                                                </a>
                                                <a href="#" @click="joinCompany(false)" class="btn btn_primary">
                                                    <span class="btn__text">No</span>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="companyname-notify-popup"
                                            :class="{ 'active' : this.company.regCompanyErrNotify }">
                                            This Startup name is taken, enter a new one
                                        </div>
                                    </div>

                                    <div class="col col_6 col_mob-p-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label
                                                v-if="this.user.register_role == 'expert'"
                                                class="form-field__title">
                                                Field of Expertise
                                                    *</label>
                                                <label
                                                v-else-if="this.user.register_role == 'investor'"
                                                class="form-field__title">
                                                Type of Investments
                                                    *</label>
                                                <label
                                                v-else
                                                class="form-field__title">
                                                Industry of Start-Up
                                                    *</label>
                                            </div>


                                            <div v-if="this.user.register_role === 'investor'"
                                            class="form-field__content">
                                                <div :class="{'dropdown_active': selectToI}" class="dropdown">
                                                  <div @click.prevent="openDropDown('selectToI')" class="dropdown__init btn btn_select">
                                                    <span class="title-text">
                                                        {{ company.companyInfo.investment ? company.companyInfo.investment : 'Type of Investments'}}
                                                    </span>
                                                    <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                                                    </svg>
                                                  </div>
                                                  <div class="dropdown__content one_column">
                                                    <ul class="dropdown__list">
                                                      <li v-for="industry in company.regCompanyInvestment" :key="industry.id" class="dropdown__item">
                                                        <div class="dropdown__link pointer"
                                                          :class="{'jcf-selected': industry.selected}"
                                                          @click.prevent="selectTypeOfInvestments(industry.id)">
                                                            <span>{{industry.value}}</span>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div v-if="this.regToIErrNotify" class="form-errors">{{ this.regToIErrNotify }}</div>
                                            </div>

                                            <div v-else
                                            class="form-field__content">
                                                <div :class="{'dropdown_active': selectIndustry}" class="dropdown noselect">
                                                  <div @click.prevent="openDropDown('selectIndustry')" class="dropdown__init btn btn_select">
                                                    <span class="title-text">
                                                      {{ company.regCompanyIndustry.filter(a => a.selected).length ?
                                                        company.regCompanyIndustry.filter(a => a.selected).map(a => a.value).join(', ') :
                                                        user.register_role == 'entrepreneur' ? 'Please select Industry' : 'Please select Field of Expertise' }}
                                                    </span>
                                                    <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                                                    </svg>
                                                  </div>
                                                  <div class="dropdown__content one_column">
                                                    <ul class="dropdown__list">
                                                      <li v-for="(industry, index) in company.regCompanyIndustry" :key="industry.id" class="dropdown__item">
                                                        <div class="custom-check dropdown__link">
                                                          <label class="custom-check__label">
                                                            <input class="custom-check__input"
                                                              v-model="industry.selected"
                                                              @change="inputChangeIndustry(index)"
                                                              type="checkbox" name="chekbox">
                                                            <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                                                <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                                              </g>
                                                            </svg>
                                                            <span class="custom-check__text">{{industry.value}}</span>
                                                          </label>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div v-if="this.regIndustryErrNotify" class="form-errors">{{ this.regIndustryErrNotify }}</div>
                                            </div>

                                        </div><!-- //form-field -->

                                    </div>
                                </div>

                                <div class="row">
                                    <div v-if="this.user.register_role == 'investor'" class="col col_6 col_mob-p-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label
                                                class="form-field__title">
                                                Field of Focus *</label>
                                            </div>

                                             <div class="form-field__content">
                                                <div :class="{'dropdown_active': selectIndustry}" class="dropdown noselect">
                                                  <div @click.prevent="openDropDown('selectIndustry')" class="dropdown__init btn btn_select">
                                                    <span class="title-text">
                                                      {{ company.regCompanyIndustry.filter(a => a.selected).length ?
                                                        company.regCompanyIndustry.filter(a => a.selected).map(a => a.value).join(', ') :
                                                        user.register_role == 'entrepreneur' ? 'Please select Industry' : 'Please select Field of Expertise' }}
                                                    </span>
                                                    <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                                                    </svg>
                                                  </div>
                                                  <div class="dropdown__content one_column">
                                                    <ul class="dropdown__list">
                                                      <li v-for="(industry, index) in company.regCompanyIndustry" :key="industry.id" class="dropdown__item">
                                                        <div class="custom-check dropdown__link">
                                                          <label class="custom-check__label">
                                                            <input class="custom-check__input"
                                                              v-model="industry.selected"
                                                              @change="inputChangeIndustry(index)"
                                                              type="checkbox" name="chekbox">
                                                            <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                                                <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                                              </g>
                                                            </svg>
                                                            <span class="custom-check__text">{{industry.value}}</span>
                                                          </label>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div v-if="this.regIndustryErrNotify" class="form-errors">{{ this.regIndustryErrNotify }}</div>
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>

                                    <div class="col col_6 col_mob-p-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Your City /Town *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <div class="form-field__content">
                                                  <input
                                                  id="pac-input"
                                                  class="form-field__input"
                                                  type="text"
                                                  required
                                                  :placeholder="'Please enter your City /Town'">
                                              </div>
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                        v-if="!this.user.access_token && !this.user.social_login"
                         class="steps__item step">
                            <div class="step__header">
                                <div class="step__number">
                                    <span class="number-text">2</span>
                                </div>
                                <div class="step__title">
                                    <span class="title-text">Registration</span>
                                </div>
                            </div>
                            <div class="step__content">
                                <div class="row">
                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Email: *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input
                                                autocomplete="false"
                                                v-model="user.invitation.email" class="form-field__input" type="email"
                                                        placeholder="Please enter email" :required="true" disabled>
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Password *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input
                                                autocomplete="false"
                                                v-model="password" v-on:input="passwordHandler"
                                                class="form-field__input" type="password"
                                                        placeholder="Please enter password" :required="true">
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>

                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Re-write Password
                                                    *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input
                                                autocomplete="false" class="form-field__input" type="password"
                                                        placeholder="Please enter password"
                                                        v-model="repassword" :required="true">
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header content-between">
                                                <span class="form-field__title">Password strength:</span>
                                                <span class="form-field__progress" :class="passStrengthText">{{this.passStrengthValue}}</span>
                                            </div>
                                            <div class="form-field__content">
                                                <div class="progressbar">
                                                    <div :class="passStrengthClass">

                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                    <div class="password-notify-popup"
                                      :class="{ 'active' : this.regPassErrNotify }">
                                      {{ regPassErrNotify }}
                                  </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>

                <div class="section__footer">
                  <div class="btn-group content-between">
                    <router-link :to="{name: 'account_create'}" class="btn btn_secondary">Cancel</router-link>
                    <button type="submit" class="btn btn_primary">
                      <span class="btn__text">Sign Up</span>
                    </button>
                  </div>
                </div>

            </div>
        </form>
    </div>

</Reg-layouts>
</template>

<script>
    import store from '../store'
    import RegLayouts from "../layouts/Reg.vue"
    import { passwordStrength } from 'check-password-strength'

    const passCustomOptions = [
      {
        id: 0,
        value: "Too weak",
        minDiversity: 0,
        minLength: 0
      },{
        id: 1,
        value: "Weak",
        minDiversity: 2,
        minLength: 4
      },{
        id: 2,
        value: "Medium",
        minDiversity: 3,
        minLength: 6
      },{
        id: 3,
        value: "Strong",
        minDiversity: 3,
        minLength: 8
    }];

    export default {
        name: "Register",
        components: {
            RegLayouts,
        },
        data() {
            return {
                firstname: '',
                lastname: '',
                companyname: '',
                registration_number: '',
                password: '',
                repassword: '',
                selectedCountry: '',
                selectedState: '',
                selectedCity: '',
                registeredBusiness: false,
                passStrengthValue: '',
                passStrengthId: '',
                passStrengthClass: 'progressbar__0',
                passStrengthText: 'strength-text__0',
                regPassErrNotify: '',
                regIndustryErrNotify: '',
                regToIErrNotify: '',
                location: '',

                selectIndustry: false,
                selectToI: false,
                selectSoF: false,
            }
        },
        beforeMount() {
            let data = {
              'company_id': localStorage.inv_company_id,
              'invitation_id': localStorage.inv_invitation_id,
              'user_email_hash': localStorage.inv_uid
            };

            store.dispatch('ACCEPT_INVITATION', data);

            store.dispatch('USER_INVITATION_ROLE', localStorage.inv_reg_role);

            store.dispatch('GET_COMPANY_INDUSTRY', this.user.register_role);
            console.log(this.user.invitation);
        },
        mounted() {
            console.log(this.user.invitation);
            if(this.user.invitation.type == "company") this.companyname = this.user.invitation.companyname;

            var self = this;
            var options = {
                types: ['(cities)']
            };
            const input = document.getElementById("pac-input");
            const autocomplete = new google.maps.places.Autocomplete(input, options);
            this.location = google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                self.selectedCity = place.address_components[0].long_name;
                for (let index = 0; index < place.address_components.length; index++) {
                    if(place.address_components[index].types[0] == "country"){
                        self.selectedCountry = place.address_components[index].long_name;
                    }
                }
            });
            setTimeout(() => {input.setAttribute('autocomplete','new-password');}, 1000);
            input.addEventListener('click', function() {
                input.setAttribute('autocomplete','new-password');
            });
        },
        updated() {
          console.log('upd');
        },
        methods: {
            passwordHandler: function() {
                if(this.password.length <=0){
                    this.passStrengthValue = '';
                    this.passStrengthId = '';
                    this.passStrengthClass = 'progressbar__0';
                    this.passStrengthText = 'strength-text__0';
                  } else {
                    this.passStrengthValue = passwordStrength(this.password, passCustomOptions).value;
                    this.passStrengthId = passwordStrength(this.password, passCustomOptions).id;

                    switch (this.passStrengthId) {
                        case 0:
                            this.passStrengthClass = 'progressbar__25';
                            this.passStrengthText = 'strength-text__25';
                            break;
                        case 1:
                            this.passStrengthClass = 'progressbar__50';
                            this.passStrengthText = 'strength-text__50';
                            break;
                        case 2:
                            this.passStrengthClass = 'progressbar__75';
                            this.passStrengthText = 'strength-text__75';
                            break;
                        case 3:
                            this.passStrengthClass = 'progressbar__100';
                            this.passStrengthText = 'strength-text__100';
                            break;
                    }
                }
            },
            signup: function() {

                this.regPassErrNotify = '';
                if(this.password !== this.repassword) {
                  this.regPassErrNotify = 'Password and Re-Password do not match';
                  setTimeout(() => {
                    this.regPassErrNotify = '';
                  }, 3000);
                  return;
                }
                else if(passwordStrength(this.password, passCustomOptions).id < 3) {
                  this.regPassErrNotify = 'Capital letters and numbers are required.';
                  setTimeout(() => {
                    this.regPassErrNotify = '';
                  }, 3000);
                  return;
                }

                if(this.company.regCompanyIndustry.filter(a => a.selected).length <= 0){
                    this.regIndustryErrNotify = 'This field is required.';
                    setTimeout(() => {this.regIndustryErrNotify = '';}, 3000);
                    return;
                }

                if(this.user.register_role == "investor" && this.company.regCompanyInvestment.filter(a => a.selected).length <= 0){
                    this.regToIErrNotify = 'This field is required.';
                    setTimeout(() => {this.regToIErrNotify = '';}, 3000);
                    return;
                }
                var data = {};
                var company = {};
                data.account_type = this.user.register_role;
                data.city = this.selectedCity;
                data.country = this.selectedCountry;

                company.id= this.company.regCompanyId;
                company.companyname = this.companyname;
                company.companycity = this.selectedCity;
                company.companycountry =  this.selectedCountry;
                company.registration_number = this.registration_number;
                company.companyindustry = this.company.regCompanyIndustry.filter(a => a.selected).map(a => a.id);
                company.type_of_investment = this.company.regCompanyInvestment.filter(a => a.selected).map(a => a.id)[0] ?? "";

                data.company = company;
                data.email = this.user.invitation.email;
                data.lastname = this.lastname;
                data.name = this.firstname;
                data.password = this.password;
                if(this.user.invitation.type == "company") data.company_id = this.user.invitation.company_id;
                if(this.user.invitation.type == "page") data.company = company;
                data.invitation_id = this.user.invitation.invitation_id;
                data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                data.type = this.user.invitation.type;
                data.params = this.user.invitation.params;

                store.dispatch('INVITATION_SIGNUP', data);
            },
            popupRemove: function() {
                store.dispatch('USER_REG_ERROR', false);
            },

            companynameHandler: function() {
                if(this.companyname.length >= 1) {
                    store.dispatch('SEARCH_COMPANY', this.companyname);
                } else {
                    store.dispatch('RESET_COMPANY_NAME');
                }
            },
            joinCompany: function(arg) {
                if(arg) {
                    store.dispatch('REG_ACCEPT_JOIN_COMPANY');
                } else {
                    store.dispatch('REG_REJECT_JOIN_COMPANY');
                }
            },

            openDropDown(target) {
              let old = this[target];
                this.selectIndustry = false;
                this.selectToI = false;
                this.selectSoF = false;
                this[target] = !old;
            },
            closeSelect(e) {
              if (!e.target.closest(".dropdown_active")) {
                this.selectIndustry = false;
                this.selectToI = false;
                this.selectSoF = false;
              }
            },

            inputChangeIndustry(current) {
              console.log(this.user.register_role)
              if (this.user.register_role == 'investor') return;
              let self = this;
              let count = 0;
              let indexes = [];
              this.company.regCompanyIndustry.forEach(function(item, index) {
                if (item.selected) {
                  indexes.push(index);
                  count ++;
                  if (count > 4) {
                    if (current == indexes[indexes.length - 2])
                      self.company.regCompanyIndustry[indexes[indexes.length - 3]].selected = false;
                    else
                      self.company.regCompanyIndustry[indexes[indexes.length - 2]].selected = false;
                  }
                }
              });
            },

            selectTypeOfInvestments(id) {
              let self = this;
              self.company.regCompanyInvestment.forEach((item, index) => {
                if (item.id == id) {
                  self.company.regCompanyInvestment[index].selected = true;
                  self.company.companyInfo.investment = item.value;
                } else {
                  self.company.regCompanyInvestment[index].selected = false;
                }
              });
              this.selectToI = false;
            },
        },
        computed: {
            company: () => store.state.company,
            user: () => store.state.user
        },

        created() {
          document.addEventListener('click', this.closeSelect);
        },
        beforeUnmount() {
          document.removeEventListener('click', this.closeSelect)
        }
    }
</script>
